@font-face {
  font-family: 'Brentscript';
  src: url('assets/brentscript.ttf') format('truetype');
}

* {
  box-sizing: border-box;
  color: #333;
  font-family: 'Poppins', sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  background: linear-gradient(to bottom, #b1c7d4 0, #e7e8e9 100%);
  min-height: 100vh;
}

a {
  text-decoration: none;
  border-bottom: 1px dotted;
}

a:hover {
  border-bottom: 1px solid;
}
